import * as React from "react";
const Logo = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={40}
        width={178}
        viewBox="0 0 900.45 163.13"
        {...props}
    >
        <path d="M89.03,115.6l-3.14-.83c-.99-.33-1.72-.91-2.19-1.73-.47-.82-.7-2.09-.7-3.8V5.36c0-1.59-.45-2.89-1.36-3.88-.91-.99-2.27-1.48-4.09-1.48-1.1,0-2.31,.18-3.63,.54-1.32,.36-3.03,.92-5.12,1.69l-15.18,5.61c-1.49,.55-2.5,1.11-3.05,1.69-.55,.58-.83,1.33-.83,2.27,0,.77,.22,1.4,.66,1.9,.44,.5,1.18,.88,2.23,1.16l4.29,.33c.93,.16,1.62,.55,2.06,1.15,.44,.6,.66,1.62,.66,3.05v29.32c-.77-.56-1.54-1.1-2.35-1.59-5.09-3.08-10.93-4.62-17.54-4.62-7.43,0-14.15,1.76-20.17,5.28-6.02,3.52-10.8,8.53-14.32,15.02-3.52,6.49-5.28,14.14-5.28,22.94,0,7.59,1.49,14.33,4.46,20.22,2.97,5.89,7.01,10.49,12.13,13.82s10.95,4.99,17.49,4.99c6.93,0,13.27-1.94,19.02-5.82,2.58-1.74,5.01-3.8,7.31-6.13,.06,2.9,1.02,5.27,2.92,7.08,1.95,1.87,4.39,2.81,7.3,2.81h16.83c1.65,0,2.85-.32,3.59-.95,.74-.63,1.11-1.47,1.11-2.52,0-.88-.26-1.62-.78-2.23-.52-.6-1.31-1.07-2.35-1.4m-34.2-6.4c-3.49,2.45-7.25,3.67-11.26,3.67-3.69,0-6.99-1.09-9.9-3.26-2.92-2.17-5.2-5.44-6.85-9.82-1.65-4.37-2.48-9.91-2.48-16.63,0-7.15,.85-13.02,2.56-17.62,1.7-4.59,4.04-8,7.01-10.23,2.97-2.23,6.33-3.34,10.07-3.34s7.56,1.12,10.98,3.34c1.64,1.07,3.21,2.42,4.7,4.03v45.46c-1.54,1.75-3.15,3.22-4.83,4.4" />
        <path d="M174.81,62.26c-3.6-6.19-8.64-11.03-15.1-14.52-6.46-3.49-13.96-5.24-22.49-5.24s-16.24,1.77-22.82,5.32c-6.57,3.55-11.72,8.4-15.43,14.56-3.71,6.16-5.57,13.18-5.57,21.04s1.81,15.24,5.45,21.46c3.63,6.22,8.66,11.08,15.1,14.61,6.44,3.52,13.92,5.28,22.44,5.28s16.24-1.79,22.82-5.36c6.57-3.57,11.72-8.46,15.43-14.65,3.71-6.19,5.57-13.16,5.57-20.92,0-8.2-1.8-15.39-5.4-21.58m-18.28,36.64c-.61,5.03-2.05,8.98-4.33,11.84-2.28,2.86-5.32,4.59-9.12,5.2-3.85,.66-7.43-.01-10.73-2.02-3.3-2.01-6.18-5.31-8.62-9.9-2.45-4.59-4.3-10.41-5.57-17.45-1.27-7.1-1.61-13.16-1.03-18.19,.58-5.03,2.01-8.98,4.29-11.84,2.28-2.86,5.32-4.59,9.12-5.2,3.85-.66,7.44,.01,10.77,2.02,3.33,2.01,6.2,5.31,8.62,9.9,2.42,4.59,4.26,10.41,5.53,17.45,1.32,7.1,1.68,13.16,1.07,18.19" />
        <path d="M264.6,108.62l-4.92-4.14c-2.6,3.73-6.14,6.75-10.54,9-4.92,2.52-10.09,3.79-15.37,3.79-6.08,0-11.14-1.18-15.04-3.51-3.85-2.29-6.97-5.1-9.28-8.34-2.3-3.21-3.9-6.57-4.77-9.99-.85-3.35-1.28-6.17-1.28-8.37v-1.5h64.03v-5.43c0-4.21-.75-8.42-2.22-12.53-1.47-4.08-3.67-7.78-6.55-10.97-2.88-3.19-6.49-5.79-10.73-7.73-4.24-1.94-9.22-2.92-14.81-2.92s-10.33,.98-14.74,2.93c-4.42,1.95-8.31,4.67-11.57,8.08-3.26,3.42-5.82,7.5-7.61,12.13-1.79,4.64-2.7,9.79-2.7,15.3s.88,11.03,2.63,15.73c1.74,4.68,4.26,8.79,7.52,12.19,3.25,3.41,7.19,6.09,11.71,7.99,4.52,1.89,9.66,2.85,15.26,2.85,6.57,0,12.67-1.23,18.12-3.67,5.01-2.24,9.34-5.9,12.88-10.9m-61.22-30.48c0-1.18,.44-3.08,1.39-5.99,.92-2.83,2.49-5.78,4.67-8.75,2.19-3,5.2-5.69,8.92-7.98,3.8-2.34,8.59-3.52,14.24-3.52,3.81,0,7.47,.7,10.88,2.09,3.4,1.38,6.39,3.29,8.89,5.67,2.49,2.38,4.49,5.17,5.94,8.29,1.46,3.16,2.2,6.59,2.2,10.2v1.5h-57.13v-1.5Z" />
        <path d="M352.19,100.11c1.9-4.69,2.86-9.92,2.86-15.53s-.96-10.83-2.86-15.53c-1.89-4.68-4.59-8.79-8-12.21-3.42-3.42-7.53-6.11-12.21-8-4.69-1.9-9.92-2.86-15.53-2.86-2.74,0-5.58,.3-8.45,.88-2.84,.58-5.58,1.49-8.16,2.69-2.56,1.2-4.98,2.75-7.18,4.58-2.17,1.81-4,3.98-5.44,6.45l-.43,.74h-2.69v-13.37h-6.9v110.87h6.9v-50.99h2.69l.43,.74c1.45,2.48,3.28,4.65,5.44,6.45,2.21,1.84,4.62,3.38,7.18,4.58,2.57,1.21,5.32,2.11,8.16,2.69,2.87,.58,5.71,.88,8.45,.88,5.61,0,10.83-.96,15.53-2.86,4.69-1.9,8.79-4.59,12.21-8,3.42-3.42,6.11-7.53,8-12.21m-22.59,14.56c-3.92,1.73-8.34,2.6-13.15,2.6s-9.11-.88-13.13-2.6c-4.01-1.72-7.55-4.07-10.53-6.99-2.98-2.93-5.37-6.42-7.09-10.38-1.73-3.97-2.6-8.25-2.6-12.73s.88-8.76,2.6-12.73c1.72-3.96,4.11-7.45,7.09-10.38,2.98-2.92,6.52-5.28,10.53-6.99,4.02-1.72,8.44-2.6,13.13-2.6s9.23,.88,13.15,2.6c3.91,1.73,7.29,4.09,10.06,7.03,2.77,2.94,4.9,6.44,6.34,10.42,1.43,3.94,2.15,8.2,2.15,12.64s-.72,8.7-2.15,12.64c-1.44,3.97-3.58,7.48-6.34,10.42-2.77,2.94-6.15,5.3-10.06,7.03" />
        <path d="M367.84,20.47c1.15,1.15,2.53,1.71,4.22,1.71s3.07-.56,4.22-1.71c1.15-1.15,1.71-2.53,1.71-4.22s-.56-3.07-1.71-4.22c-1.15-1.15-2.53-1.71-4.22-1.71s-3.07,.56-4.22,1.71c-1.15,1.15-1.71,2.53-1.71,4.22s.56,3.07,1.71,4.22" />
        <rect x={368.61} y={47.96} width={6.9} height={73.24} />
        <path d="M456.9,111.66l-5.26-4.34c-2.44,2.8-5.28,5.11-8.47,6.88-3.67,2.04-7.77,3.08-12.2,3.08-4.81,0-9.24-.88-13.15-2.6-3.91-1.72-7.29-4.09-10.06-7.03-2.77-2.95-4.9-6.45-6.34-10.42-1.43-3.94-2.15-8.19-2.15-12.64s.72-8.7,2.15-12.64c1.44-3.97,3.57-7.47,6.34-10.42,2.77-2.94,6.15-5.3,10.06-7.03,3.91-1.73,8.34-2.6,13.15-2.6,4.19,0,8.15,.98,11.76,2.9,3.18,1.69,6.16,3.99,8.88,6.82l5.51-4.13c-3.33-3.45-7.09-6.2-11.21-8.19-4.56-2.2-9.59-3.32-14.94-3.32s-10.87,.96-15.62,2.86c-4.73,1.89-8.84,4.58-12.2,7.99-3.36,3.41-6.03,7.53-7.93,12.22-1.9,4.69-2.86,9.92-2.86,15.53s.96,10.84,2.86,15.53c1.9,4.7,4.57,8.8,7.93,12.22,3.36,3.41,7.46,6.1,12.2,7.99,4.75,1.9,10.01,2.86,15.62,2.86,5.26,0,10.21-1.04,14.73-3.08,4.09-1.86,7.85-4.69,11.19-8.44" />
        <path d="M540.73,41.75c1.59,0,2.85,.39,3.75,1.16,.91,.77,1.72,1.68,2.43,2.72,.71,1.05,1.57,1.97,2.56,2.76,.99,.8,2.36,1.2,4.13,1.2,2.48,0,4.47-.92,5.98-2.76,1.51-1.84,2.27-4.28,2.27-7.3,0-3.47-1.1-6.16-3.3-8.09-2.2-1.92-5.06-2.89-8.58-2.89-4.18,0-8.17,1.38-11.97,4.13-3.75,2.72-6.93,6.86-9.55,12.42-4.76-1.56-10.15-2.35-16.19-2.35-8.09,0-15.15,1.35-21.21,4.04-6.05,2.7-10.74,6.46-14.07,11.3-3.33,4.84-4.99,10.45-4.99,16.83,0,5.56,1.53,10.59,4.58,15.1,3.05,4.51,7.43,8.06,13.12,10.64,1.52,.69,3.12,1.28,4.79,1.79-2.07,.49-3.86,1.09-5.37,1.8-3.11,1.46-5.31,3.2-6.6,5.24-1.29,2.03-1.94,4.24-1.94,6.6,0,2.59,.77,4.83,2.31,6.73,1.38,1.7,3.9,3.22,7.52,4.55-6.75,1.37-11.69,3.29-14.79,5.77-4.24,3.38-6.35,7.22-6.35,11.51,0,3.57,1.27,6.75,3.8,9.53,2.53,2.78,6.72,4.96,12.58,6.56,5.86,1.59,13.79,2.39,23.81,2.39,15.4,0,27.17-2.42,35.32-7.26,8.14-4.84,12.21-11.03,12.21-18.57,0-4.13-1.06-7.66-3.18-10.6-2.12-2.94-5.62-5.36-10.52-7.26-4.9-1.9-11.47-3.37-19.72-4.41-7.7-1.1-13.26-2.08-16.67-2.93-3.41-.85-5.56-1.66-6.44-2.43-.88-.77-1.32-1.54-1.32-2.31,0-.94,.34-1.77,1.03-2.52,.27-.29,.65-.59,1.08-.89,2.74,.4,5.63,.6,8.7,.6,8.09,0,15.14-1.35,21.16-4.04,6.02-2.69,10.7-6.46,14.03-11.3,3.33-4.84,4.99-10.45,4.99-16.83,0-5.61-1.53-10.64-4.58-15.1-2.84-4.15-6.84-7.49-11.95-10.04,.91-1.52,1.73-2.67,2.46-3.41,1.35-1.37,2.9-2.06,4.66-2.06m-30.12,90.03c6.71,1.04,11.95,2.12,15.72,3.22,3.77,1.1,6.4,2.36,7.88,3.79,1.48,1.43,2.23,3.11,2.23,5.03,0,2.36-.77,4.42-2.31,6.15-1.54,1.73-4,3.05-7.38,3.96-3.38,.91-7.85,1.36-13.41,1.36-9.68,0-16.48-1.43-20.38-4.29-3.91-2.86-5.86-6.63-5.86-11.3,0-2.59,1.09-4.91,3.26-6.97,1.61-1.53,3.78-2.69,6.49-3.48,3.73,.89,8.31,1.73,13.77,2.53m13.24-41.3c-2.01,4.04-5.3,6.23-9.86,6.56-3.08,.22-5.85-.54-8.29-2.27-2.45-1.73-4.44-4.37-5.98-7.92-1.54-3.55-2.61-7.88-3.22-13-.55-5-.48-9.25,.21-12.75,.69-3.49,2.01-6.18,3.96-8.04,1.95-1.87,4.44-2.92,7.47-3.14,4.68-.33,8.54,1.53,11.59,5.57,3.05,4.04,5.02,9.92,5.9,17.62,.83,7.54,.23,13.33-1.77,17.37" />
        <path d="M625.76,47.74c-6.46-3.49-13.96-5.24-22.49-5.24s-16.24,1.77-22.81,5.32c-6.57,3.55-11.72,8.4-15.43,14.56-3.71,6.16-5.57,13.18-5.57,21.04s1.81,15.24,5.45,21.46c3.63,6.22,8.66,11.08,15.1,14.61,6.44,3.52,13.92,5.28,22.44,5.28s16.24-1.79,22.82-5.36c6.57-3.57,11.72-8.46,15.43-14.65,3.71-6.19,5.57-13.16,5.57-20.92,0-8.2-1.8-15.39-5.4-21.58-3.6-6.19-8.64-11.03-15.1-14.52m-3.18,51.16c-.61,5.03-2.05,8.98-4.33,11.84-2.28,2.86-5.32,4.59-9.12,5.2-3.85,.66-7.43-.01-10.73-2.02-3.3-2.01-6.18-5.31-8.62-9.9-2.45-4.59-4.3-10.41-5.57-17.45-1.27-7.1-1.61-13.16-1.03-18.19,.58-5.03,2.01-8.98,4.29-11.84,2.28-2.86,5.32-4.59,9.12-5.2,3.85-.66,7.44,.01,10.77,2.02,3.33,2.01,6.2,5.31,8.62,9.9,2.42,4.59,4.26,10.41,5.53,17.45,1.32,7.1,1.68,13.16,1.07,18.19" />
        <path d="M721.23,47.74c-6.46-3.49-13.96-5.24-22.49-5.24s-16.24,1.77-22.81,5.32c-6.57,3.55-11.72,8.4-15.43,14.56-3.71,6.16-5.57,13.18-5.57,21.04s1.81,15.24,5.45,21.46c3.63,6.22,8.66,11.08,15.1,14.61,6.44,3.52,13.92,5.28,22.44,5.28s16.24-1.79,22.82-5.36c6.57-3.57,11.72-8.46,15.43-14.65,3.71-6.19,5.57-13.16,5.57-20.92,0-8.2-1.8-15.39-5.4-21.58-3.6-6.19-8.64-11.03-15.1-14.52m-3.18,51.16c-.61,5.03-2.05,8.98-4.33,11.84-2.28,2.86-5.32,4.59-9.12,5.2-3.85,.66-7.43-.01-10.73-2.02-3.3-2.01-6.18-5.31-8.62-9.9-2.45-4.59-4.3-10.41-5.57-17.45-1.27-7.1-1.61-13.16-1.03-18.19,.58-5.03,2.01-8.98,4.29-11.84,2.28-2.86,5.32-4.59,9.12-5.2,3.85-.66,7.44,.01,10.77,2.02,3.33,2.01,6.2,5.31,8.62,9.9,2.42,4.59,4.26,10.41,5.53,17.45,1.32,7.1,1.68,13.16,1.07,18.19" />
        <path d="M841.71,117.01c-.52-.6-1.31-1.07-2.35-1.4l-3.14-.83c-.99-.33-1.72-.91-2.19-1.73-.47-.82-.7-2.09-.7-3.8V5.36c0-1.59-.45-2.89-1.36-3.88-.91-.99-2.27-1.48-4.08-1.48-1.1,0-2.31,.18-3.63,.54-1.32,.36-3.03,.92-5.12,1.69l-15.18,5.61c-1.49,.55-2.5,1.11-3.05,1.69-.55,.58-.83,1.33-.83,2.27,0,.77,.22,1.4,.66,1.9,.44,.5,1.18,.88,2.23,1.16l4.29,.33c.94,.16,1.62,.55,2.06,1.15,.44,.6,.66,1.62,.66,3.05v29.32c-.77-.56-1.54-1.1-2.35-1.59-5.09-3.08-10.93-4.62-17.54-4.62-7.43,0-14.15,1.76-20.17,5.28-6.02,3.52-10.8,8.53-14.32,15.02-3.52,6.49-5.28,14.14-5.28,22.94,0,7.59,1.48,14.33,4.46,20.22,2.97,5.89,7.01,10.49,12.13,13.82,5.12,3.33,10.95,4.99,17.49,4.99,6.93,0,13.27-1.94,19.02-5.82,2.58-1.74,5.01-3.8,7.31-6.13,.06,2.9,1.02,5.27,2.92,7.08,1.95,1.87,4.39,2.81,7.3,2.81h16.83c1.65,0,2.85-.32,3.59-.95,.74-.63,1.11-1.47,1.11-2.52,0-.88-.26-1.62-.78-2.23m-36.55-7.8c-3.49,2.45-7.25,3.67-11.26,3.67-3.69,0-6.99-1.09-9.9-3.26-2.92-2.17-5.2-5.44-6.85-9.82-1.65-4.37-2.47-9.91-2.47-16.63,0-7.15,.85-13.02,2.56-17.62,1.7-4.59,4.04-8,7.01-10.23,2.97-2.23,6.33-3.34,10.07-3.34s7.56,1.12,10.97,3.34c1.65,1.07,3.21,2.42,4.7,4.03v45.46c-1.54,1.75-3.15,3.22-4.83,4.4" />
        <polygon points="871.86 39.81 861.09 54.44 850.32 46.33 861.49 31.83 843.8 25.71 848.05 13.08 865.48 19.33 865.48 .98 879.04 .98 879.04 19.33 896.46 13.61 900.45 26.24 883.03 31.83 887.31 37.7 876.14 45.68 871.86 39.81" />
        <path d="M889.79,41.26s2.01,2.65,2.18,2.99c.16,.34,1.91,3.26-1.77,5.62-1.09,.7-7.3,5.24-7.3,5.24l-4.29-5.87,11.17-7.98Z" />
    </svg>
);
export default Logo;
