import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformMany from '../Transformer/TransformMany';
import Star from './Media/logo-star.png'

function Questions() {
    const [questions, setQuestions] = useState([]);
    const [ind, setInd] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchQuestions();
                setQuestions(TransformMany(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching questions: ", err);
            }
        }
        init();
    }, [])

    const setActive = (index) => {
        if (ind === index) {

            setInd(null);
        } else {
            setInd(index);
        }
    }

    const renderQuestions = (item, index) => (
        <div key={index} className="col-12 mt-5" style={{ borderBottom: '1px solid black' }}>
            <div className="row">
                <div className="col-10 d-flex align-items-center" style={{ fontSize: '18px', fontFamily: "var(--secondary)" }}>
                    <strong>
                        {item.Question}
                    </strong>
                </div>
                <div onClick={() => setActive(index)} className=" d-flex  align-items-center justify-content-center" style={{ cursor: 'pointer', fontSize: '50px', fontWeight: 'lighter', borderRadius: '50%', backgroundColor: index === ind ? 'black' : 'white', transition: '0.3s ease-in-out', color: index === ind ? 'white' : 'black', width: "50px", height: '50px',border:'1px solid black' }}>
                    {ind === index ? (
                        <>-</>
                    ) : (
                        <>+</>
                    )}

                </div>
                <div className="col-12 mt-5" style={{ height: index === ind ? '80px' : '0px', overflowY: 'scroll', transition: '0.3s ease-in-out', fontSize: '13px', color: 'grey' }}>
                    {item.Answer}
                </div>
            </div>
        </div>
    )

    return (
        <div className='row'>
            <div className="col-12">
                <div className="row">
                    <div className="col-12 col-lg-5" style={{ fontSize: 'var(--subTitle2)' }}>
                        <strong>Questions</strong> you<br />
                        might be asking<br />
                        yourself
                        <img src={Star} width="80%" alt="" />
                    </div>
                    <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center px-5" style={{ borderLeft: '1px solid black' }}>
                        <div className="row">
                            {questions.map(renderQuestions)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Questions