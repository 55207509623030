import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformMany from '../Transformer/TransformMany';

function Services() {
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchServices();
                console.log(res.data);
                setService(TransformMany(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching services: ", err);
                setError('Failed to fetch services. Please try again later.');
            } finally {
                setLoading(false);
            }
        }
        init();
    }, []);

    const RenderService = (item, index) => (
        <div key={index} className="col-12 col-md-6 py-5" style={{ borderRight: index % 2 === 0 ? '1px solid grey' : 'none', borderTop: index > 1 ? '1px solid grey' : 'none' }}>
            <div className="row">
                <div className="col-12 col-lg-auto mt-2 d-flex align-items-start justify-content-center justify-content-lg-start">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="60px" alt="" />
                </div>
                <div className="col-12 col-lg-10 d-flex align-items-center">
                    <div className="row">
                        <div dangerouslySetInnerHTML={{ __html: item.Title }} className="col-12 text-center text-lg-start" style={{ fontSize: 'var(--subTitle2)', fontWeight: 'lighter' }} />
                        <div dangerouslySetInnerHTML={{ __html: item.Description }} className="col-12 mt-2"  style={{fontSize:'13px'}}/>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="row">
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {!loading && !error && service.map(RenderService)}
        </div>
    );
}

export default Services;
