import { AxiosInstance } from "../Axios/Axios";



const endpoints = {
    fetchHome: `/api/home?populate=*`,
    fetchServices: `/api/services?populate=*&sort[0]=id`,
    fetchTestimonial: `/api/testimonials?populate=*`,
    fetchQuestions: `/api/questions?populate=*`,
    fetchSustainability: `/api/sustainabilities?populate=*`

}

export const RequestManager = {

    async fetchHome() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchHome);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchServices() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchServices);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchTestimonial() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchTestimonial);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchQuestions() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchQuestions);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchSustainability() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSustainability);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },


}