import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformMany from '../Transformer/TransformMany';

function Sustainability() {
    const [sustain, setSustain] = useState([]);

    useEffect(() => {
        const init = async () => {
            try {

                const res = await RequestManager.fetchSustainability();
                setSustain(TransformMany(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching sustainabiliy: ", err);
            }
        }
        init();
    }, [])


    const renderSustain = (item, index) => (
        <div key={index} className="col-12 col-lg-4 pl-5 d-flex align-items-center justify-content-center mt-5" style={{ borderLeft: index === 2 ? 'none' : '1px solid grey' }}>
            <div className="row">
                <div className="col-2">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="40px" alt="" />
                </div>
                <div className="col-8" style={{ fontWeight: 'lighter' }}>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center" style={{ fontSize: "18px" }}>

                            {item.Title}
                        </div>
                        <div className="col-12 mt-3" style={{ fontSize: '14px' }}>
                            {item.Description}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
    return (
        <div className='col-12'>
            <div className="row">
                <div className="col-12 col-lg-4 d-block align-self-center" style={{ fontSize: 'var(--subTitle2)', fontFamily: 'var(--secondary)' }}>
                    <strong> 5 ways</strong><br />
                    to become more<br />
                    <strong> sustainable</strong>, today.
                </div>
                {sustain.map(renderSustain)}
            </div>
        </div>
    )
}

export default Sustainability