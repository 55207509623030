function TransformSingle(data) {
    return {
        id: data.id,
        ...data.attributes,
        Image: data?.attributes?.Image?.data?.attributes?.url,
        Sustainability: data?.attributes?.Sustainability?.data?.attributes?.url,
    };
}

export default TransformSingle;
