import React, { useState } from 'react'
import Back from './Media/conact.jpg'
import Cert from '../Home/Media/CPDcertified-BW.png'

function Contact() {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        reason: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', formState.name);
        formData.append('email', formState.email);
        formData.append('reason', formState.reason);
        formData.append('message', formState.message);
    }
    return (
        <div className='container-fluid px-0'>

            <div className="row" style={{ borderBottom: '1px solid black', borderTop:'1px solid black' }}>
                <div className="col-12 col-lg-6">
                    <img src={Back} width="100%" alt="" />
                </div>
                <div className="col-12 col-lg-6  px-5 d-flex align-items-center">
                    <div className="row h-100 d-flex flex-column justify-content-around">
                        <div className="col-6  px-0" style={{ fontSize: 'var(--subTitle2)', fontFamily: 'var(--primary)', }}>
                            <div className="row">
                                <div className="col-12 py-2 px-0" style={{ borderBottom: '1px solid grey' }}>

                                    Let's&nbsp;
                                    <strong>
                                        Connect
                                    </strong>
                                </div>
                                <div className="col-12 py-2 px-0" style={{ fontSize: 'var(--regular)' }}>

                                    Sign up for regular updates about upcoming courses, sprints, events, and thought pieces around sustainable marketing.
                                </div>
                            </div>

                        </div>
                        <div className="col-9 mt-5">
                            <div className="row">
                                <div className="col-9 px-0" style={{ borderBottom: '1px solid black' }}>
                                    Sustainable strategies that make an impact


                                </div>
                                <div className="col-3 d-flex align-items-end">
                                    <img src={Cert} width="57px" alt="Cert" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="container px-5" style={{ marginTop: "10rem", marginBottom: "10rem", fontSize: 'var(--regular)', color: "var(--dark)" }}>
                    <div className="row px-0 px-lg-5">
                        <div className="col-12" style={{ backgroundColor: 'var(--grey)' }}>
                            <form >
                                <div className="row px-0 py-5 p-lg-5 ">
                                    <div className="col-12" style={{ fontSize: 'var(--subTitle)' }}>
                                        <strong>CONTACT</strong> US
                                    </div>
                                    <div className="col-12">

                                        One of our sustainable experts will be in touch with you soon.

                                    </div>
                                    <div className="col-12 mt-5">
                                        <input className='w-100 p-3 mt-3' type="text" placeholder='Name*' style={{ border: 'none', }} />
                                    </div>
                                    <div className="col-12">
                                        <input className='w-100 p-3 mt-3' type="email" placeholder='Email*' style={{ border: 'none', }} />
                                    </div>
                                    <div className="col-12">
                                        <select className='w-100 p-3 mt-3' name="reason" id="" style={{ border: 'none', }}  >
                                            <option value="" disabled selected>Reason*</option>

                                            <option value="Speak to a human">Speak to a human</option>
                                            <option value="Sign up to our newsletter">Sign up to our newsletter</option>
                                            <option value="Become an instructor">Become an instructor</option>
                                            <option value="Find out how to partner with us">Find out how to partner with us</option>
                                            <option value="Suggest a potential course">Suggest a potential course</option>
                                            <option value="Work with us">Work with us</option>
                                            <option value="Just for a chitty-chat">Just for a chitty-chat</option>
                                            <option value="Something else">Something else?</option>



                                        </select>
                                    </div>
                                    <div className="col-12">
                                        <textarea name="" id="" className='w-100 p-3 mt-3' placeholder='Message' rows="4" style={{ border: 'none', }} ></textarea>
                                    </div>
                                    <div className="col-12 ">
                                        <input className='w-100 p-2 mt-3' type="submit" value="Submit" style={{ backgroundColor: 'black', color: 'white', border: 'none' }} />

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Contact