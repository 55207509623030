import React from 'react'
import Green from './Media/star-green.png'
import Yellow from './Media/star-yellow.png'
import CPD from './Media/cpd-white.webp'
import Insta from './Media/insta.png'
import Linkedin from './Media/linkedin.png'
import { useNavigate } from 'react-router-dom'

function Footer() {

    const navigate = useNavigate();

    return (
        <div className='container-fluid py-4' style={{ backgroundColor: 'black' }}>

            <div className="container d-flex align-items-center justify-content-center ">
                <div className="row w-100 d-flex align-items-center justify-content-between" style={{ color: 'white', fontSize: '10px' }}>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row">

                            <div className="col-auto">
                                <a href="/">
                                    <img src={Green} width="25px" alt="" />
                                </a>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <a href="/">
                                    do epic good
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row">
                            <div className="col-auto">
                                <a href="https://doepic.agency/">

                                    <img src={Yellow} width="25px" alt="" />
                                </a>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <a href="https://doepic.agency/">

                                    do epic sh*t
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row ">
                            <div className="col-auto">
                                <a href="https://cpduk.co.uk/providers/do-epic">
                                    <img src={CPD} width="80px" alt="" />
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row ">
                            <div className="col-auto">
                                <a href="https://www.linkedin.com/company/do-epic-good/">
                                    <img src={Linkedin} width="35px" alt="" />
                                </a>
                            </div>
                            <div className="col-auto">
                                <a href="https://www.instagram.com/doepicgood/">

                                    <img src={Insta} width="35px" alt="" />
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row ">
                            <div className="col-auto">
                                Privacy policy
                            </div>


                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row ">
                            <div className="col-auto">
                                Terms of service
                            </div>


                        </div>
                    </div>
                    <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                        <div className="row ">
                            <div className="col-auto">
                                <a href="/contact">

                                    Get in touch with a human
                                </a>
                            </div>


                        </div>
                    </div>
                    <div className="col-12 text-center mt-2" style={{ fontSize: 'var(--regular)' }}>
                        © 2024, do epic good
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer