import React, { useEffect, useState } from 'react';
import Slider from './Slider';
import { RequestManager } from '../Requests/RequestManager';
import TransformSingle from '../Transformer/TransformSingle';
import Services from '../Services/Services';
import Testimonial from '../Testimonial/Testimonial';
import Background from './Media/Background.jpg'
import Questions from '../Q&A/Questions';
import Sustainability from '../Sustainability/Sustainability';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';


function Home() {
    const [home, setHome] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchHome();
                console.log(res?.data);
                setHome(TransformSingle(res?.data) ?? {});

            } catch (err) {
                console.error("Error in fetching home page data: ", err);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        }
        init();
    }, []);

    return (
        <div className='container-fluid px-0'>
            <div className="row">
                <div className="col-12">
                    <Slider />
                </div>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div className="spinner"></div>  {/* Add a spinner for visual feedback */}
                    Loading...
                </div>
            ) : error ? (  // Display error if it exists
                <div className="error-message">
                    {error}
                </div>
            ) : (
                <div className="container-fluid " style={{ backgroundColor: 'var(--grey)' }}>

                    <div className="container py-5">



                        <div className="row" style={{ fontSize: '26px' }}>
                            <div className="col-12 text-center mb-5" style={{ fontFamily: 'var(--secondary)', fontSize: 'var(--subTitle2)' }}>
                                <strong> Why is sustainability good for business?</strong>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: home.info_Left }} className="col-12 col-lg-4 mt-5 mt-lg-0 d-block align-self-center" style={{ borderRight: '1px solid grey' }}></div>
                            <div dangerouslySetInnerHTML={{ __html: home.info_Center }} className="col-12 col-lg-4 mt-5 mt-lg-0 d-block align-self-center" style={{ borderRight: '1px solid grey' }}></div>
                            <div dangerouslySetInnerHTML={{ __html: home.info_Right }} className="col-12 col-lg-4 mt-5 mt-lg-0 d-block align-self-center"></div>
                        </div>


                    </div>




                </div>

            )}

            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <Services />
                    </div>
                </div>

            </div>

            <div className="container-fluid mt-5 py-5 mx-0" style={{ backgroundColor: 'var(--grey)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={`${process.env.REACT_APP_API_URL}${home.Sustainability}`} width="100%" alt="" />
                                    &#123;<i>Source: Black Friday 2023 – Ecoalf</i> &#125;
                                </div>
                                <div className="col-12 col-lg-6" style={{ color: "var(--dark)" }}>
                                    <div className="row h-100 px-5">
                                        <div className="col-12" style={{ fontSize: 'var(--subTitle2)', lineHeight: '40px' }}>
                                            <strong style={{ fontSize: '52px' }}> Why</strong><br />
                                            sustainability<br />
                                            as a strategy?

                                        </div>
                                        <div className="col-12">
                                            Implementing sustainable marketing strategies and business practices is not just good for the planet— it's a strategic move that drives profitability and customer loyalty. It opens doors to enhanced investment opportunities and positions your brand to attract top-tier talent who prioritise sustainability.
                                        </div>
                                        <div className="col-12" style={{ fontSize: '18px' }}>
                                            <strong>

                                                Together, let’s make a change for a thriving future.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row mt-5">
                    <div className="col-12">
                        <Testimonial />
                    </div>
                </div>
                <div className="row " style={{ marginTop: '10rem' }}>
                    <div className="col-12">
                        <img src={Background} width="100%" alt="" />
                        &#123;<i>source: adidas</i> &#125;
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12" >
                        <Questions />
                    </div>
                </div>
            </div>
            <div className="row mt-5 py-5" style={{ backgroundColor: 'var(--grey)' }}>
                <div className="col-12">
                    <div className="container">

                        <Sustainability />
                    </div>
                </div>
            </div>
            <div className="container mt-2">
                <div className="row">
                    <div className="col-12">
                        <div className="row p-5">
                            <div className="col ml-auto px-0" style={{ fontSize: 'var(--veryBig)', lineHeight: '100px' }}>
                                <a href="/contact">
                                    LET'S TALK
                                </a>
                            </div>
                            <div onClick={() => navigate("/contact")} className="col-1 p-0 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                <svg data-name="Isolation Mode" className='w-100' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.27 77.27"><path d="M77.27 22.36h-16.5v26.75L11.67 0 0 11.67l49.11 49.1H22.36v16.5h54.91z" /></svg>
                            </div>
                            <div className="col-12" style={{ fontSize: '14px' }}>

                                Sign up for regular updates about upcoming courses, sprints, events, and thought-pieces around sustainable marketing.

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
