import React, { useEffect, useState } from 'react'
import Icon from './Media/icon.png'
import { RequestManager } from '../Requests/RequestManager';
import TransformMany from '../Transformer/TransformMany';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Background from './Media/Background.jpg'

function Testimonial() {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchTestimonial();
                setTestimonials(TransformMany(res?.data) ?? [])
            } catch (err) {
                console.error("Error in fetching testimonials: ", err);
            } finally {
                setLoading(false);
            }
        }
        init();
    }, [])

    const renderTestimonials = (item, index) => (
        <SwiperSlide key={index} className='p-5 h-100' style={{ backgroundColor: 'white', border: '1px solid black', boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.1)' }}>
            <div className="col-12">
                {item.Review}
            </div>
            <div className="col-12 mt-2">
                -<i>{item.FullName}</i>
            </div>
            <div className="col-12">
                {item.Title}
            </div>
            <div className="col-12">
                {item.Company}
            </div>
            <div className="col-12 mt-2" style={{ color: 'grey' }}>
                {item.Course}
            </div>

        </SwiperSlide>
    )

    return (
        <div className='row d-flex justify-content-end'>
            <div className="col-12">
                <div className="row " style={{ position: 'absolute' }}>

                    <div className="col-12 col-lg-5 d-flex align-items-center">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 h-100 d-flex align-items-center">
                                <img src={Icon} width="50%" alt="" />
                            </div>
                            <div className="col-12 d-flex align-items-center mt-3" style={{ fontSize: 'var(--subTitle2)', fontFamily: 'var(--secondary)' }}>
                                Testimonials
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7">
                        <img src={Background} width="100%" alt="" />
                    </div>
                </div>
            </div>
            {loading ? (

                <div className="col-12">
                    Loading...
                </div>
            ) : (
                <div className="col-12 col-lg-8 h-100 d-flex align-items-center">


                    <Swiper
                        className='d-flex align-items-center mt-5'
                        spaceBetween={50}
                      
                       breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        1000: {
                            slidesPerView: 2
                        }
                       }}
                    >
                        {testimonials.map(renderTestimonials)}
                    </Swiper>
                </div>

            )}
        </div>
    )
}

export default Testimonial