import React from 'react'
import Logo from '../Logo/Logo'

function Navbar() {
    return (
        <div className='container-fluid py-0 px-5 py-lg-3 mt-3'>
            <div className="row">
                <div className="col-12">
                    <a href="/">

                        <Logo />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Navbar