import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import Hero from './Media/Hero.jpg';
import Visual from './Media/visual-1.png';
import Visual2 from './Media/visual-2.jpeg'
import Visual3 from './Media/visual-3.png'
import TextField from '@mui/material/TextField';
import Cert from './Media/CPDcertified-BW.png';
import { AxiosInstance } from '../Axios/Axios';  // Import your Axios instance
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Slider() {
    // State to handle form input
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        agree: false
    });
    const [loading, setLoading] = useState(false);

    // Handle form data changes
    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value
        });
    };

    // Form submission handler using Axios
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await AxiosInstance.post('/api/coffe-mails', {
                data: {
                    FirstName: formData.firstName,
                    LastName: formData.lastName,
                    Email: formData.email,
                    Agree: formData.agree
                }
            });
            toast.success('Form successfully submitted!');
            setFormData({ firstName: '', lastName: '', email: '', agree: false });

        } catch (error) {
            toast.error('Error submitting the form');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='row Height d-flex align-items-center' style={{ borderTop: '1px solid black', borderBottom: '2px solid black' }}>
            <div className="col-12 col-lg-7 h-100 d-flex align-items-center m-0 p-0" style={{ borderRight: '2px solid black' }}>
                <Swiper modules={[Pagination]} pagination={{ clickable: true }}>
                    {/* Coffee lander */}
                    <SwiperSlide>
                        <div className="row px-5" style={{ fontSize: 'var(--title2)', fontFamily: 'var(--secondary)', lineHeight: 1 }}>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mt-5 mt-lg-0 col-lg-6 d-block text-center text-lg-start align-self-center">
                                        It's like a<br />
                                        <strong>caffeine shot<br /></strong>
                                        for <i>your</i> <strong>career.</strong>
                                    </div>
                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center pt-2">
                                        <img src={Visual} width="100%" alt="Visual" />
                                    </div>
                                    <div className="col-12 text-center text-lg-start" style={{fontSize:'var(--text)'}}>
                                        Demand for <strong>green</strong> skills is on the rise.<br />
                                        Get certified before the weeks' end
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form className='Form' onSubmit={handleSubmit}>
                            <div className="row px-5">
                                <div className="col-12 px-2 pl-lg-3  mt-lg-0" style={{ fontSize: 'var(--text)', fontFamily: 'var(--secondary)', lineHeight: 1 }}>
                                    <br />
                                    <strong>Join</strong> the waitlist
                                </div>
                            </div>

                            <div className="row px-5">
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        type='text'
                                        className="w-100"
                                        id="firstName"
                                        label="First Name"
                                        placeholder='First Name*'
                                        required
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        className="w-100"
                                        id="lastName"
                                        label="Last Name"
                                        placeholder='Last Name*'
                                        required
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-10 p-2">
                                    <input
                                        className="w-100"
                                        id="email"
                                        label="Email"
                                        placeholder='Email*'
                                        required
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <button className="p-2 Submit" type="submit" disabled={loading}>
                                        {loading ? <span>Loading...</span> : "Fast-track your career"}
                                    </button>
                                </div>
                                <div className="col-12 d-flex my-3">
                                    <div className="row">
                                        <div className="col-1">

                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={formData.agree}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-11">
                                            <label htmlFor="agree" className='d-flex align-items-end' area-label="agreement box"> By signing up you agree to receive doepicgood marketing communications</label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </SwiperSlide>
                    {/* Rise lander */}
                    {/* <SwiperSlide>
                        <div className="row px-5" style={{ fontSize: 'var(--text)', fontFamily: 'var(--primary)', lineHeight: 1 }}>
                            <div className="col-12">
                                <div className="row d-flex flex-column-reverse flex-lg-row">
                                    <div className="col-12 mt-5 mt-lg-0 col-lg-6 d-block text-start text-lg-start align-self-center" >
                                        <div className="row">
                                            <div className="col-12 mt-lg-5 pb-3" style={{ borderBottom: '1px solid grey' }}>

                                                <strong style={{ fontSize: 'var(--title)' }}> only 12%</strong><br />
                                                of professionals have <strong>green skills.</strong>
                                            </div>
                                        </div>
                                        <div className="col-12 text-start mt-3 mt-lg-0 text-lg-start" style={{ fontSize: 'var(--title)' }}>
                                            <strong>Rise</strong> above<br />
                                            the other<br />
                                            <strong>88%</strong>
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-6 d-flex align-items-start justify-content-center pt-2 h-25">
                                        <img src={Visual2} className='Visual2' alt="Visual" />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <form className='Form' onSubmit={handleSubmit}>
                            <div className="row px-5">
                                <div className="col-12 px-2 pl-lg-3  mt-lg-0" style={{ fontSize: 'var(--text)', fontFamily: 'var(--primary)', lineHeight: 1 }}>
                                    <br />
                                    Add <strong>green skills</strong> to your skillset
                                </div>
                            </div>

                            <div className="row px-5">
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        type='text'
                                        className="w-100"
                                        id="firstName"
                                        label="First Name"
                                        placeholder='First Name*'
                                        required
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        className="w-100"
                                        id="lastName"
                                        label="Last Name"
                                        placeholder='Last Name*'
                                        required
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-10 p-2">
                                    <input
                                        className="w-100"
                                        id="email"
                                        label="Email"
                                        placeholder='Email*'
                                        required
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <button className="p-2 Submit" type="submit" disabled={loading}>
                                        {loading ? <span>Loading...</span> : "Fast-track your career"}
                                    </button>
                                </div>
                                <div className="col-12 d-flex my-3">
                                    <div className="row">
                                        <div className="col-1">

                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={formData.agree}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-11">
                                            <label htmlFor="agree" className='d-flex align-items-end' area-label="agreement box"> By signing up you agree to receive doepicgood marketing communications</label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                        <div className="row px-5" style={{ fontSize: 'var(--text)', fontFamily: 'var(--primary)', lineHeight: 1 }}>
                            <div className="col-12">
                                <div className="row d-flex flex-lg-row">
                                    <div className="col-12 col-lg-12 mt-4 d-flex align-items-center justify-content-center ">
                                        <img src={Visual3} className='Visual3' alt="Visual" />
                                    </div>
                                    <div className="col-12 mt-5 mt-lg-0 col-lg-12 d-block text-center text-lg-start align-self-center" style={{ fontSize: "var(--subTitle)" }}>
                                        <strong>58%</strong> of global companies<br />
                                        admit to <strong>greenwashing</strong>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <form className='Form' onSubmit={handleSubmit}>
                            <div className="row px-5">
                                <div className="col-12 px-2 pl-lg-3  mt-lg-0" style={{ fontSize: 'var(--text)', fontFamily: 'var(--primary)', lineHeight: 1 }}>
                                    <br />
                                    <strong>Join</strong> the waitlist
                                </div>
                            </div>

                            <div className="row px-5">
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        type='text'
                                        className="w-100"
                                        id="firstName"
                                        label="First Name"
                                        placeholder='First Name*'
                                        required
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 col-lg-5 p-2">
                                    <input
                                        className="w-100"
                                        id="lastName"
                                        label="Last Name"
                                        placeholder='Last Name*'
                                        required
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 col-lg-10 p-2">
                                    <input
                                        className="w-100"
                                        id="email"
                                        label="Email"
                                        placeholder='Email*'
                                        required
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <button className="p-2 Submit" type="submit" disabled={loading}>
                                        {loading ? <span>Loading...</span> : "Fast-track your career"}
                                    </button>
                                </div>
                                <div className="col-12 d-flex my-3">
                                    <div className="row">
                                        <div className="col-1">

                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={formData.agree}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-11">
                                            <label htmlFor="agree" className='d-flex align-items-end' area-label="agreement box"> By signing up you agree to receive doepicgood marketing communications</label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </SwiperSlide> */}
                    <SwiperSlide className='d-flex align--center h-100'>
                        <div className="row">


                            <div className="col-12 ">

                                <img className='d-none d-lg-block' src={Hero} height="100%" alt="Hero" style={{ objectFit: 'cover' }} />
                                <img className='d-block d-lg-none' src={Hero} width="100%" alt="Hero" style={{ objectFit: 'contain' }} />
                            </div>
                            <div className="col-12 px-5 d-flex d-lg-none align-items-start  mt-5 mt-lg-0 ">
                                <div className="row  px-2 d-flex justify-content-center flex-column" style={{ height: '100%' }}>

                                    <div className="col-12 text-start text-lg-center pb-4" style={{ fontSize: 'var(--title)', fontFamily: 'var(--secondary)', lineHeight: 1, borderBottom: '1px solid black' }}>
                                        <strong>Coming</strong><br />
                                        <i style={{ fontFamily: 'var(--primary)', fontWeight: 'lighter' }}>SOON</i>
                                    </div>
                                    <div className="col-12 mt-4">
                                        Globally certified e-learning platform for <br />
                                        Sustainable Marketing and Business Practices
                                    </div>

                                    {/* Spacer to center the content */}
                                    <div className="col-12 mt-3 mt-lg-5" style={{ fontFamily: 'var(--secondary)' }}>
                                        &#91;Launching Oct 2024 &#93;
                                    </div>

                                    {/* Last col-12 element aligned at the bottom */}
                                    <div className="col-12 d-flex   mb-5 " style={{ marginTop: '8rem' }}>
                                        <div className="row">
                                            <div className="col-10">
                                                Certified courses from leading experts
                                            </div>
                                            <div className="col-2 d-flex align-items-end">
                                                <img src={Cert} width="57px" alt="Cert" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="col-12 d-none d-lg-flex mt-5 mt-lg-0 align-items-center h-100 col-lg-5">
                <div className="row h-75 px-2 d-flex flex-column">
                    {/* First 2 col-12 elements */}
                    <div className="col-12 text-center pb-4" style={{ fontSize: 'var(--title)', fontFamily: 'var(--secondary)', lineHeight: 1, borderBottom: '1px solid black' }}>
                        <strong>Coming</strong><br />
                        <i style={{ fontFamily: 'var(--primary)', fontWeight: 'lighter' }}>SOON</i>
                    </div>
                    <div className="col-12 mt-4">
                        Globally certified e-learning platform for <br />
                        Sustainable Marketing and Business Practices
                    </div>

                    {/* Spacer to center the content */}
                    <div className="col-12 mt-5" style={{ fontFamily: 'var(--secondary)' }}>
                        &#91;Launching Oct 2024 &#93;
                    </div>

                    {/* Last col-12 element aligned at the bottom */}
                    <div className="col-12 mt-auto">
                        <div className="row">
                            <div className="col-10" style={{ borderBottom: '1px solid black' }}>
                                Certified courses from leading experts
                            </div>
                            <div className="col-2 d-flex align-items-end">
                                <img src={Cert} width="57px" alt="Cert" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}

export default Slider;
